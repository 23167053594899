import React, { useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox, Grid, IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveTab, selectSelectedPolicies, setSelectedPolicies } from "../../../../Pages/BookAnalysis/BookAnalysisSlice";
import clsx from "clsx";
import { frozenGridColumnsPositions } from "../../../../global/constants";
import { selectUserSubscriptions } from "../../../../AppSlice";
import { ReactComponent as Note } from '../../../../Assets/icon-note.svg';
import { ReactComponent as Check } from '../../../../Assets/icon-checked-flat.svg';
import { ReactComponent as Box } from '../../../../Assets/icon-unchecked-flat.svg';
import NotePopup from "../../../Popups/PolicyPopup/NotePopup/NotePopup";
import { User } from "../../../../global/user";

export const renderTableRow = (rowData, columnsToHide = [], onRowClick = () => {}) => {
        return Object.keys(rowData)
        .filter((key) => !columnsToHide?.includes(key) || columnsToHide.length === 0)
        .map((key) => (
            <TableCell 
                component="th" 
                scope="row" 
                style={key === "policy" ? 
                    {
                    fontWeight: 700,
                    lineHeight: "16px",
                    color: "#363ED3",
                    cursor: "pointer"
                    } : {}
                }
                onClick={() => onRowClick(rowData)}>
                {rowData[key]}
            </TableCell>
        ))
}

const PoliciesTable = ({ 
    page, 
    data, 
    headers, 
    sort, 
    onSort, 
    renderRow = renderTableRow, 
    loaded = true, 
    onRowClick = () => {},
    divided = false,
    editable = false,
    divideIndex = 4,
    frozenColumns = [],
    frozenPositions = frozenGridColumnsPositions,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const width = window.innerWidth * .09
    const activeTab = useSelector(selectActiveTab)
    const subscriptions = useSelector(selectUserSubscriptions)
    const selectedPolicies = useSelector(selectSelectedPolicies)
    const [openNote, setOpenNote] = useState(false)
    const [selectedPolicy, setSelectedPolicy] = useState(null)
    const [selectedPolicyName, setSelectedPolicyName] = useState("")

    const isAE = activeTab === "ProspectsAE"
    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;
    const isFreeSubcriptionPlan = isBroker && !subscriptions.find(({ name }) => name === "basic")

    const handleOpenNotes = (id, name) => {
        setSelectedPolicy(id)
        setSelectedPolicyName(name ? name : "")
        setOpenNote(true)
    }
    
    const handleCloseNotes = () => {
        setSelectedPolicy(null)
        setSelectedPolicyName("")
        setOpenNote(false)
    }
    
    const handleSelectPolicy = (e, item) => {
        dispatch(setSelectedPolicies({
            ...Object.assign({}, ...Object.keys(selectedPolicies).filter((key) => key !== "all").map(((key) => ({[key]: selectedPolicies[key]})))),
            [item]: e.target.checked
        }))
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            dispatch(setSelectedPolicies(
                {
                    "all": true
                }
            ))
        } else {
            dispatch(setSelectedPolicies({}))
        }
    }

    return (
        <TableContainer>
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    {editable && <TableCell style={{ padding: 0, width: 32}}>
                        <div className={classes.checkContainer}>
                            <Checkbox onChange={(e) => handleSelectAll(e)} checkedIcon={<Check className={classes.checkedIcon}/>} icon={<Box className={classes.checkedIcon}/>}/>
                        </div>
                    </TableCell>
                    }
                    {
                        headers.map((item, index) => (
                        (divided && index === divideIndex) ? 
                        <React.Fragment>
                            <TableCell component="th" scope="row" style={{ maxWidth: width  }} key={`policy-table-header-${item}`}>
                                <div>
                                    <p>{item}</p>
                                    <ArrowDropDownIcon onClick={() => onSort(item)} style={(sort?.includes("-")) ? { transform: "rotate(180deg)" } : {}}/>
                                </div>
                            </TableCell>
                            <TableCell component="th" scope="row" style={isAE ? { width: 5, padding: 0, background: "#FFFFFF" } : { width: "0.02%", background: "#FFFFFF"}}  key={`policy-table-header-${item}-split`}/>
                        </React.Fragment>
                        :
                        <TableCell
                        component="th" 
                        scope="row" 
                        key={`policy-table-header-${item}`}
                        className={clsx(
                            item === "Upside Opportunity" ? classes.tableUpsideHeader : "",
                            frozenColumns.includes(item) ? classes.sticky : "",
                            )
                        }
                        style={index < 4 ? 
                            { 
                                maxWidth: width,
                            } : 
                            {
                                right: frozenColumns.indexOf(item) > -1 ? frozenPositions[(frozenColumns.length-1) - frozenColumns.indexOf(item)] : 0,  
                                boxShadow: frozenColumns.indexOf(item) > -1 && (frozenColumns.indexOf(item) === 0 || frozenColumns.length === 1) ? "-4px 4px 5px #EEF0F450" : "none", 
                                borderLeft: frozenColumns.indexOf(item) > -1 && (frozenColumns.indexOf(item) === 0 || frozenColumns.length === 1) ? "5px solid #FFF" : "none" 
                            }
                        }>  
                            <div>
                                <p>{item}</p>
                                <ArrowDropDownIcon onClick={() => onSort(item)} style={(sort?.includes("-")) ? { transform: "rotate(180deg)" } : {}}/>
                            </div>
                        </TableCell>
                        ))
                        }
                </TableRow>                    
            </TableHead>
            {
                loaded ? 
                <TableBody>
                    {
                        data
                        ?.map((item, index) => (
                            <TableRow className={clsx(classes.tableBody, index > 4 && isFreeSubcriptionPlan ? classes.tableRowBlurry : '', selectedPolicies[item.id] || selectedPolicies["all"] ? classes.tableRowSelected : '') } key={`policy-table-row-${page}-${index}`}>
                                {editable && <TableCell style={{ padding: 0, width: 32}}>
                                    <div className={classes.checkContainer}>
                                        {selectedPolicies["all"] 
                                        ? <Checkbox checked  checkedIcon={<Check className={classes.checkedIcon}/>} icon={<Box className={classes.checkedIcon}/>}/>
                                        : <Checkbox key={item.id} onChange={(e) => handleSelectPolicy(e, item.id)} checkedIcon={<Check className={classes.checkedIcon}/>} icon={<Box className={classes.checkedIcon}/>}/>
                                        }
                                    </div>
                                </TableCell>
                                }
                                {renderRow(item, headers, index > 4 && isFreeSubcriptionPlan ? () => {} : onRowClick)}
                            </TableRow>
                        ))
                    }
                </TableBody>
                :
                <Grid container justifyContent="center" alignContent="center" style={{minHeight: 600}}>
                    <CircularProgress style={{color: "#000",width:96,height:96}}/>
                </Grid>
                }
        </TableContainer>
    )
}

export default PoliciesTable