import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDefaultColumns, setModel } from "../../Components/Run/RunSlice";
import Run from "../../Components/Run/Run";
import appApiService from "../../AppService";
import { setColumns } from "../../AppSlice";

const AppService = new appApiService()

const RunPage = () => {
  const dispatch = useDispatch()
  const defaultColumns = useSelector(selectDefaultColumns)

  useEffect(() => {
    AppService._getRuns(
        localStorage.getItem("companyID"),
        (res) => {
            if (res.data?.count !== 0) {
                const columnsConfig = res.data?.projects[0]?.runs[0]?.columns_to_display?.columns
                const tmp = {}
                if (columnsConfig?.length > 0) {
                    Object.assign(tmp, ...Object.keys(defaultColumns)
                        .map((key) => ({[key]: columnsConfig.includes(key) || ["AUM Upside", "Premium Upside"].includes(key)})))
                    dispatch(setColumns(
                        tmp
                    ))
                } else {
                    Object.assign(tmp, ...Object.keys(defaultColumns)
                        .map((key) => ({[key]: defaultColumns[key]})))
                    dispatch(setColumns(
                        tmp
                    ))
                }

            } else {
                AppService._getDemoRunsData((res) => {
                    const columnsConfig = res.data?.projects[0]?.runs[0]?.columns_to_display?.columns
                    const tmp = {}
                    if (columnsConfig?.length > 0) {
                        Object.assign(tmp, ...Object.keys(defaultColumns)
                            .map((key) => ({[key]: columnsConfig.includes(key) || ["AUM Upside", "Premium Upside"].includes(key)})))
                        dispatch(setColumns(
                            tmp
                        ))
                    } else {
                        Object.assign(tmp, ...Object.keys(defaultColumns)
                            .map((key) => ({[key]: defaultColumns[key]})))
                        dispatch(setColumns(
                            tmp
                        ))
                    }
                },
                (error) => { console.log(error) })
            }
        }, 
    (error) => { console.log(error); }
    )
  }, [dispatch, defaultColumns])
    
  useEffect(() => {
    if(sessionStorage.getItem("run_data")) {
      const runData = JSON.parse(sessionStorage.getItem("run_data"))
      dispatch(setModel(runData.model))
    }
  }, [dispatch])
    
    return (   
        <>
          <Run/>
        </>     
    )
}

export default RunPage;