import React, { useState, useEffect } from "react";
import { Button, Grid, makeStyles, MenuItem, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { ReactComponent as IconArrow } from "../../../Assets/icon-growth.svg";
import { ReactComponent as InfoIcon } from '../../../Assets/icon-info2.svg';
import { getCountryCurrencySymbol, nFormatter } from "../../../global/helpers";
import CampaignTypeSelect from "../CampaignTypeSelect/CampaignTypeSelect";
import { useDispatch, useSelector } from "react-redux";
import { selectIds, selectOpportunities, selectProductsData, selectStoriesIsLoading, setImpactsData, setOpportunities, setOpportunitiesBroker, setProjectIds, setProjectLabels, setStoriesIsLoading } from "../BookAnalysisSlice";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { setDate, setDuration, setEffectiveDate, setModel, setPolicies, setPotential, setRunName } from "../../../Components/Run/RunSlice";
import { setProjectName } from "../../NewProject/NewProjectSlice";
import { selectUserCompany, setActiveOption, setColumns } from "../../../AppSlice";
import { setPrecisionConfig } from "../../../Components/Run/Charts/ChartsSlice";
import moment from "moment";
import clsx from "clsx";
import { ArrowDropDown } from "@mui/icons-material";
import { ReactComponent as IconArrowUp } from "../../../Assets/icon-arrow-up.svg";
import { basicButtonStyles } from "../../../Components/ui/buttons";
import CreateCampaignsDialog from "../CreateCampaignsDialog/CreateCampaignsDialog";
import BookAnalysisService from "../BookAnalysisService";
import UnregisteredPopup from "../../../Components/Popups/UnregisteredPopup/UnregisteredPopup";
import { InfoTooltip } from "../../../Components/ui/tooltips";
import NoDataIndicator from "../../../Components/Run/Charts/NoDataIndicator/NoDataIndicator";
import { uiViewButtonStyles } from "../../../Components/ui/buttons";
import Loader from "../../../Components/Loader/Loader";
import appApiService from "../../../AppService";
import { resetFilters } from "../../../Components/Filters/FiltersSlice";
import { User } from "../../../global/user";
import BrokerOportunityItem, { BuildCampaignButton } from "./BrokerOportunityItem";
import campaignApiService from "../../Campaigns/CampaignService";

const useStyles = makeStyles(() => ({
    opportunityHeader: {
        display: "flex",
        alignItems: "center",
        gap: 40,
    },
    opportunityHeaderContainer: {
        paddingBottom: 24
    },
    opportunityHeaderTitle: {
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 600,
        lineHeight: "32px",
        display: "flex",
        paddingBottom: 12
    },
    opportunityHeaderItem: {
        display: "flex",
        alignItems: "center",
        gap: 6
    },
    additionalStatsValue: {
        fontFamily: "Roboto",
        fontSize: 20,
        fontWeight: 500,
        padding: "10px 16px",
        background: "#F6F6F6",
        borderRadius: 6,
    },
    additionalStatsText: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
    },
    opportunityText: {
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 400,
        lineHeight: "29px"
    },
    highLightText: {
        color: "#363ED3",
        fontWeight: 700,
        ".MuiTableCell-root &": {
            color: "#363ED3"
        }
    },
    bgDark: {
        borderRadius: 6,
        background: "#F9FAFB",
        height: 60
    },
    tableContainer: {
        flexDirection: "column",
        "& .MuiTableContainer-root": {
            background: "#FFFFFF",
        }
    },
    tableHead: {
        "& .MuiTableCell-root": {
          background: "#F9F9F9",
          height: 32,
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: 14,
          textAlign: 'center',
          padding: '8px 0',
          border: 'none',
          borderRadius: 0

        },
        "& .MuiTableRow-root": {
            borderRadius: 8
        }
    },
    tableBody: {
        "& .MuiTableCell-root": {
          background: "#F3F9FD",
          height: 60,
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '20px',
          padding: '8px 0',
          textAlign: 'center',
          borderBottom: "8px solid #FFFFFF",
        },
        "& .MuiTableRow-root": {
            "& .MuiTableCell-root:first-child": {
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8
            },
            "& .MuiTableCell-root:last-child": {
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8
            }
        }
    },
    tableCellId: {
        "&.MuiTableCell-root": {
            fontWeight: 600,
            textTransform: "capitalize",
            textAlign: "left",
            paddingLeft: 28,
            "& .MuiTypography-root": {
                fontWeight: 600,
                fontSize: 14,
                lineHeight: "20px",
            }
        }
    },
    tableHeaderCellId: {
        "&.MuiTableCell-root": {
            textAlign: "left",
            paddingLeft: 28,
            width: 256
        }
    },
    tableCellAlignStart: {
        "&.MuiTableCell-root": {
            textAlign: "left"
        }
    },
    tableCellLapse: {
        color: "#383ECB"
    },
    tableCellUI: {
        color: "#8D0669"
    },
    tableFooterContainer: {
        display: "flex",
        height: 85,
        background: "#FFFFFF",
        boxShadow: "-30px 0px 60px 0px #1E1F2217"
    },
    footerGrid: {
        width: "100%",
        paddingLeft: 115,
        textAlign: "center",
        display: "grid",
        gridTemplateColumns: "256px 12% 10% 16% 18% auto",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '28px',
        color: "#383ECB",
        "@media (max-width:1281px)": {
            gridTemplateColumns: "256px 11% 8% 14% 15% auto",
        },
        "@media (max-width:1081px)": {
            gridTemplateColumns: "256px 7% 10% 8% 16% auto",
        },
        "& div:first-child": {
            fontWeight: 600,
            fontSize: 24,
            lineHeight: '32px',
            color: "#4C4C4C",
            justifyContent: "start" 
        },
        "& div:last-child": {
            justifyContent: "start" 
        }
    },
    footerGridItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    tableFooterContent: {
        display: "none",
        "& .MuiTableHead-root": {
            visibility: "hidden",
            "& .MuiTableCell-root": {
              borderBottom: "none"
            }
        },
        "& .MuiTableBody-root": {
            position: "relative",
            bottom: 38
        }
    },
    tableFooter: {
        "& .MuiTableCell-root": {
          background: "#FFFFFF",
          height: 44,
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: 18,
          lineHeight: '28px',
          color: "#383ECB"
        },
        "& .MuiTableCell-root:first-child": {
            borderBottomLeftRadius: 8,
            fontWeight: 600,
            fontSize: 24,
            lineHeight: '32px',
            color: "#4C4C4C",
            textAlign: "left" 
        },
        "& .MuiTableCell-root:last-child": {
            borderBottomRightRadius: 8
        }
    },
    totalIcon: {
        borderRadius: "50%",
        background: "rgba(133, 212, 178, 1)",
        boxShadow: "0 0 0 3px rgba(133, 212, 178, 0.27)",
        width: 17,
        height: 17,
        textAlign: "center", 
        marginRight: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& path": {
            fill: "#fff"
        },
        "& svg": {
            marginBottom: "1px",
            transform: "rotate(90deg)"
        }
    },
    infoIconBox: {
        border: "1px solid #C2C2C2",
        borderRadius: "50%",
        width: 16,
        height: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        marginLeft: 7
    },
    runsSelect: {
        visibility: "hidden",
        top: 13,
        "& svg": {
            color: "#000000"
        }
    },
    runsSelectLapse: {
        visibility: "hidden",
        top: 43,
        right: 143,
        "& svg": {
            color: "#000000"
        }
    },
    runsSelectItem: {
        padding: "5px 12px",
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '30px',
        color: "#312E40",
        width: 215,
        justifyContent: "center",
        textTransform: "capitalize"
    },
    expandContainer: {
        background: "#FFFFFF",
        marginTop: 20,
        "& svg": {
            marginRight: 8
        },
        "& path": {
            stroke: "#666666"
        }
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 6,
        borderTopLeftRadius: 0,
        padding: "24px",
        marginBottom: 104,
        "& .nodata-indicator--container": {
        },
        "& .nodata-indicator--text": {
        }
    },
    recomendedContainer: {
        gap: 32,
    },
    backdrop: {
        position: "fixed",
        height: "100vh",
        background: "#00000095",
        top: 0,
        left: 80,
        zIndex: 1000
    }
}))

const RunResultsButton = styled(Button)(() => ({
      ...basicButtonStyles,
      borderRadius: '8px',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      color: '#FFF',
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "24px",
      width: 142,
      minHeight: 44,
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 15px",
      '&:hover': {
        opacity: '0.85',
        backgroundColor: '#363ED3',
        borderColor: '#FFF',
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        backgroundColor: '#1D2398',
        color: '#FFFFFF'
      },
      '&:active': {
        backgroundColor: '#1D2398'
      },
      "@media (max-width:1228px)": {
        width: 'unset'
      },
  })); 

const RunCampaignsButton =  styled(Button)(() => ({
    ...uiViewButtonStyles,
    backgroundColor: "#363ED3",
    maxWidth: "fit-content",
    minHeight: 44,
    padding: 24,
    padding: "10px 15px",
    '&:hover': {
        opacity: '0.85',
        backgroundColor: "#363ED3",
        borderColor: '#FFF',
        boxShadow: 'none',
    },
    '&:active': {
        backgroundColor: '#1D2398'
    },
    '&.Mui-disabled': {
      backgroundColor: '#1D2398',
      color: '#FFFFFF'
    },
}));

const ExpandButton =  styled(Button)(() => ({
    backgroundColor: "#FFFFFF",
    color: "#666666",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    padding: 0,
    '&:hover': {
        backgroundColor: "#FFFFFF",
        opacity: '0.85'
    },
}));

const ApiService = new BookAnalysisService()
const AppService = new appApiService()
const CampaignService = new campaignApiService()

const Opportunities = ({ outSideRef, projectType }) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const tableData = useSelector(selectOpportunities)
    const headersData = useSelector(selectProductsData)
    const userType = useSelector(selectUserCompany)
    const demo = history.location.search.includes("demo") || ["platform-anonym", "anonym_broker"].includes(userType) 
    const projectIds = useSelector(selectIds)
    const isStoriesLoading = useSelector(selectStoriesIsLoading)
    const [ uiRunsSelectOpen, setUiRunsSelectOpen ] = useState(false)
    const [ lapseRunsSelectOpen, setLapseRunsSelectOpen ] = useState(false)
    const [ openCampaignsDialog, setOpenCampaignsDialog ] = useState(false)
    const [ openRegisterDialog, setOpenRegisterDialog ] = useState(false)
    const currency = getCountryCurrencySymbol()
    const [ expandRetentionTable, setExpandRetentionTable ] = useState(false)
    const [ expandNewBusinessTable, setExpandNewBusinessTable ] = useState(false)
    const [ campaignsData, setCampaignsData ] = useState({
        opportunitiesLapse: [],
        opportunitiesUI: []
    })
    const [ provider, setProvider ] = useState(6)
    const [ showAll, setShowAll ] = useState(false)

    const isAnnuties = projectType === "annuities"
    const isBroker = localStorage.getItem("accountType") === "broker" && User().isHideOnProd
    // move empty table to bottom
    const isReverse = tableData?.opportunitiesUI?.length > 0 && !tableData?.opportunitiesLapse?.length
    
    const handleCloseCampaignsDialog = () => {
        setOpenCampaignsDialog(false)
    }

    const handleUiClick = () => {
        if (headersData?.ui_runs?.length > 1) {
            setUiRunsSelectOpen(true)
        } else {
            handleDataModelingClick(headersData.ui_runs[0])
        }
    }

    const handleLapseClick = () => {
        if (headersData?.lapse_runs?.length > 1) {
            setLapseRunsSelectOpen(true)
        } else {
            handleDataModelingClick(headersData.lapse_runs[0])
        }
    }

    const handleDataModelingClick = (model) => {
        dispatch(resetFilters())
        if (model) {
            const update = moment(model.updated_at).format("MMM DD, YYYY")
            dispatch(setDate(model.created_at))
            dispatch(setEffectiveDate(model.config?.effective_date ? moment(model.config.effective_date).format("MMM DD, YYYY") : update))
            dispatch(setPolicies(model.project.total_policies))
            dispatch(setPrecisionConfig(model.precision_cfg))
            dispatch(setPotential(model.project.total_premium))
            dispatch(setModel(model.model_type))
            dispatch(setProjectName(model.project.project_name))
            dispatch(setRunName(model.run_name))
            dispatch(setDuration(model.period_months))
            dispatch(setActiveOption("Run"))
            sessionStorage.setItem(
              "run_data", 
              JSON.stringify(
                { 
                  projectName: model.project.project_name, 
                  runName: model.run_name, 
                  model: model.model_type, 
                  date: moment(model.created_at).format("MMM DD, YYYY"), 
                  policies_in_run: model.project.total_policies, 
                  premium: model.project.total_premium,
                  premium_loss: model.project.potential_premium_loss,
                  cfg: model.precision_cfg,
                  duration: model.period_months,
                  effective_date: model.config?.effective_date ? model.config.effective_date : update,
                }
              )
            )
            history.push(`/run?${model.run_id}${demo ? "&demo=true" : ""}`)
        }
    }

    const handleCampaignsPopupOpen = () => {
        if (["platform-anonym", "anonym_broker"].includes(userType)) {
            setOpenRegisterDialog(true)
        } else if (demo) {
            history.push("/campaigns")
        } else {
            setOpenCampaignsDialog(true)
        }

    }
    
    const handleChangeStory = (story, model, value) => {
        const data = model === "lapse" 
        ? campaignsData?.opportunitiesLapse?.filter(({ name }) => name !== story) 
        : campaignsData?.opportunitiesUI?.filter(({ name }) => name !== story) 
        setCampaignsData({
            opportunitiesLapse: model === "lapse" ? [
                ...data,
                { name: story, action: value }
            ] : campaignsData.opportunitiesLapse,
            opportunitiesUI: model === "ui" ? [
                ...data,
                { name: story, action: value }
            ] : campaignsData.opportunitiesUI,
        })
    }

    const handleRunAllCampaigns = (callback = () => {}) => {
        if (isBroker) {
            CampaignService._postCreateCampaignBroker({
                filter_string : `story_upsell=${campaignsData.name}`,
                action: campaignsData.action,
                color: "#0090FB",
                name: campaignsData.name,
                provider
            }, 
            () => {
                callback()
            }, (error) => { console.log(error); })
        } else {
            const lapseStories = campaignsData?.opportunitiesLapse
            const uiStories = campaignsData?.opportunitiesUI
            const runs = []
            headersData?.lapse_runs?.length && runs.push(...headersData.lapse_runs.map(({ run_id }) => run_id))
            headersData?.ui_runs?.length && runs.push(...headersData.ui_runs.map(({ run_id }) => run_id))
    
            if (lapseStories?.length) {
                ApiService._postCampaignsStories({
                    run_ids: runs,
                    stories: lapseStories
                },
                () => {
                    !uiStories?.length && callback()
                }, (err) => console.log(err))
            }
    
            if (uiStories?.length) {
                ApiService._postCampaignsStories({
                    run_ids: runs,
                    stories: uiStories
                },
                () => {
                    callback()
                }, (err) => console.log(err))            
            }
        }

    }

    const handleBuildCampaign = (story, model, action = "") => { 
        if (isBroker) {
            setCampaignsData(action)
        } else {
            setCampaignsData({
                opportunitiesLapse: model === "lapse" ? [
                    { name: story, action: "Schedule a Meeting" }
                ] : [],
                opportunitiesUI: model === "ui" ? [
                    { name: story, action: "Schedule a Meeting" }
                ] : [],
            })
        }

        handleCampaignsPopupOpen()
    }


    useEffect(() => {                    
        if (!projectIds?.length) {
            dispatch(setStoriesIsLoading(true))

            AppService._getRuns(
                localStorage.getItem("companyID"),
                (res) => {
                    if (res.data?.count !== 0 || ["platform-anonym", "anonym_broker"].includes(userType)) {
    
                        const meta = []
                        const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                            meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "", runs: runs.filter(({ status }) => status?.toLowerCase() !== "failed")})
                            return project_id
                        })
                        dispatch(setProjectIds(ids))
                        dispatch(setProjectLabels(meta))
                    } else { 
                        // Load demo data
                        AppService._getDemoRunsData((res) => {
                            history.push("/book?demo")                        
                            const meta = []
                            const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                                meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "", runs})
                                return project_id
                            })
                            if (!projectIds?.length) {
                                dispatch(setProjectIds(ids))
                                dispatch(setProjectLabels(meta))
    
                            }
                        },
                        (error) => { console.log(error) })

                    }

                }, 
            (error) => { dispatch(setStoriesIsLoading(false)); console.log(error);}
            )

        }
    }, [dispatch, projectIds])

    useEffect(() => {
        if (projectIds?.length && isBroker) {
            ApiService._getImpactByProducts(
                history.location.search?.includes("demo") || ["platform-anonym", "anonym_broker"].includes(userType),
                projectIds,
                (response) => {
                    dispatch(setImpactsData(response.data))
                },
                () => {}
            )
        }
    }, [dispatch, projectIds])

    useEffect(() => {
        if (isBroker) {
            dispatch(setStoriesIsLoading(true))
            ApiService._getStoriesByPolicies(demo, (response) => {
                dispatch(setOpportunitiesBroker(response.data))
                dispatch(setStoriesIsLoading(false))
            }, (error) => { dispatch(setStoriesIsLoading(false)); console.log(error); })
        } else if (projectIds?.length) {
            dispatch(setStoriesIsLoading(true))
            ApiService._getImpactByStories(demo, projectIds, (response) => {
                dispatch(setOpportunities(response.data))
                dispatch(setStoriesIsLoading(false))
            }, (error) => { dispatch(setStoriesIsLoading(false)); console.log(error); })
        }
    }, [dispatch, projectIds, demo])

    // Close the dropdown if clicked outside
    useEffect(() => {
        const checkIfClickedOutside = () => {
            if (uiRunsSelectOpen) {
                setUiRunsSelectOpen(false);
            }
            if (lapseRunsSelectOpen) {
                setLapseRunsSelectOpen(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [lapseRunsSelectOpen, uiRunsSelectOpen, outSideRef])
    
    return (
        <Grid container direction="column" justifyContent="center">
            {isStoriesLoading && <Loader/>}
            <Grid container justifyContent="center" className={classes.main}>
                {/* To Do: split broker and regular container into diffrent components */}
                {isBroker ? 
                <Grid container className={classes.recomendedContainer}>
                    {
                        tableData?.opportunitiesLapse
                            ?.filter((item) => item?.name?.toLowerCase() !== "maintain current policy")
                            ?.slice(0, showAll ? tableData.opportunitiesLapse.length : 12)
                            ?.map((data) => <BrokerOportunityItem data={data} onClick={handleBuildCampaign}/>)
                    }
                    {
                        !showAll && tableData?.opportunitiesLapse.length && <Grid container justifyContent="center">
                            <BuildCampaignButton onClick={() => setShowAll(true)}>Show All</BuildCampaignButton>
                        </Grid>
                    }
                </Grid>
                : 
                <>
                <Grid container justifyContent="center" direction={
                    isReverse ? 
                    "column-reverse" : "column"
                    }>
                    <Grid container justifyContent="flex-end" style={tableData?.opportunitiesLapse?.length > 0 ? {} : { opacity: .4 }}>  
                        {!tableData?.opportunitiesLapse?.length && <NoDataIndicator title="No models have been run to reveal new business opportunities"/>}
                        <Grid container style={isReverse ? { paddingTop: 20 } : {}}>                  
                            <Grid container justifyContent="space-between"  className={classes.opportunityHeaderContainer}>
                                <Grid item className={classes.opportunityHeaderTitle} alignItems="center">
                                    Retention
                                    <InfoTooltip title="Policy upgrades and cross-sell opportunities" placement="top">
                                        <div className={classes.infoIconBox}>
                                                <InfoIcon/>
                                        </div>
                                    </InfoTooltip>
                                </Grid>
                                <Grid className={classes.opportunityHeader}>

                                    <Grid item className={classes.opportunityHeaderItem}>
                                        <Grid item>
                                            <Typography className={classes.additionalStatsText}>Policies</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.additionalStatsValue}>
                                                <NumberFormat value={tableData.policiesLapse} displayType={'text'} thousandSeparator={true}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.opportunityHeaderItem}>
                                        <Grid item>
                                            <Typography className={classes.additionalStatsText}>{`${isAnnuties ? "Potential Upside" : "Potential Premium Upside"}`}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={clsx(classes.additionalStatsValue, classes.highLightText)}>{nFormatter(tableData.premiumLapse, 2, currency)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item justifyContent="flex-start">
                                        <RunResultsButton 
                                            disableRipple
                                            onClick={handleLapseClick} 
                                            disabled={headersData?.lapse_runs?.length === 0 || userType === "VidaCaixa"} 
                                            style={headersData?.lapse_runs?.length === 0 ? { justifyContent: "center" } : {}}>               
                                            {"Run Results"}
                                            {headersData?.lapse_runs?.length > 1 && <ArrowDropDown/>}
                                        </RunResultsButton>
                                        {
                                            headersData?.lapse_runs?.length > 1 &&
                                        <Select className={classes.runsSelect} open={lapseRunsSelectOpen} value={""} onChange={(e) => handleDataModelingClick(e.target.value)}>
                                                {
                                                    [...headersData.lapse_runs]
                                                        ?.sort((a,b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf())
                                                        ?.map((item) => <MenuItem className={classes.runsSelectItem} value={item}>{item?.project?.project_name} {item?.model_type}</MenuItem>) 
                                                }
                                        </Select> 
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.tableContainer}>
                            <TableContainer style={!expandRetentionTable ? { overflow: "hidden", maxHeight: !isReverse ? 400 : 287 } : { minHeight: 400 }}>
                                <Table>
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell component="th" scope="row" className={classes.tableHeaderCellId}>Policyholder Stories</TableCell>
                                            <TableCell component="th" scope="row">{isAnnuties ? "Total Prospects" : "Number of Prospects"}</TableCell>
                                            <TableCell component="th" scope="row">{isAnnuties ? "AUM" : "Premium"}</TableCell>
                                            <TableCell component="th" scope="row">{isAnnuties ? "Assignable Prospects" : "New Policies In Pipeline"}</TableCell>
                                            <TableCell component="th" scope="row">{isAnnuties ? "AUM of Assignable Prospects" : "New Premium In Pipeline"}</TableCell>
                                            <TableCell component="th" scope="row" className={classes.tableCellAlignStart}>Recommended action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        <TableRow style={{ background: "#F3F9FD", height: 8}}/>
                                        {
                                            tableData?.opportunitiesLapse?.map(
                                                (data) => (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={classes.tableCellId}>
                                                            {!data["opportunity"] ? "N/A" : data["opportunity"]?.length < 33 ? data["opportunity"] : <InfoTooltip title={data["opportunity"]}><Typography>{data["opportunity"].slice(0, 32)}...</Typography></InfoTooltip>}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {<NumberFormat value={data["prospects"] ? data["prospects"] : 0} displayType={'text'} thousandSeparator={true}/>}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {nFormatter(data["potential_premium"], 2, currency)}
                                                        </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {<NumberFormat value={data["assignable_prospects"] ? data["assignable_prospects"] : 0} displayType={'text'} thousandSeparator={true}/>}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {nFormatter(data["assignable_prospects_potential_premium"], 2, currency)}
                                                            </TableCell>    
                                                        <TableCell component="th" scope="row" className={clsx(classes.tableCellAlignStart, classes.tableCellLapse)}>
                                                            {<CampaignTypeSelect defaultValue={data["campaign"]} story={data["opportunity"]} model="lapse" onChange={handleChangeStory}/>}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container justifyContent="center" className={classes.expandContainer}>
                                <ExpandButton disabled={!tableData?.opportunitiesLapse?.length} onClick={() => setExpandRetentionTable(!expandRetentionTable)}>
                                <IconArrowUp style={expandRetentionTable ? {} : { rotate: "180deg" }}/>
                                    {expandRetentionTable ? "Show less" : "Show all"}
                                </ExpandButton>
                            </Grid>                                
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" style={tableData?.opportunitiesUI?.length > 0 ? {} : { opacity: .4 }}>
                        {!tableData?.opportunitiesUI?.length && <NoDataIndicator title="No models have been run to reveal new business opportunities"/>}  
                        <Grid container style={!isReverse ? { paddingTop: 20 } : {}}>                  
                            <Grid container justifyContent="space-between" className={classes.opportunityHeaderContainer}>
                                <Grid item className={classes.opportunityHeaderTitle} alignItems="center">
                                    New Business
                                    <InfoTooltip title="Policy upgrades and cross-sell opportunities" placement="top">
                                        <div className={classes.infoIconBox}>
                                                <InfoIcon/>
                                        </div>
                                    </InfoTooltip>
                                </Grid>

                                <Grid className={classes.opportunityHeader}>
                                    <Grid item className={classes.opportunityHeaderItem}>
                                        <Grid item>
                                            <Typography className={classes.additionalStatsText}>Policies</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.additionalStatsValue}>
                                                <NumberFormat value={tableData.policiesUI} displayType={'text'} thousandSeparator={true}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.opportunityHeaderItem}>
                                        <Grid item>
                                            <Typography className={classes.additionalStatsText}>{`${isAnnuties ? "Potential AUM" : "Potential Premium Upside"}`}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={clsx(classes.additionalStatsValue, classes.highLightText)}>{nFormatter(tableData.premiumUI, 2, currency)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item justifyContent="flex-start">
                                        <RunResultsButton 
                                        onClick={handleUiClick} 
                                        disabled={headersData?.ui_runs?.length === 0 || userType === "VidaCaixa"}
                                        style={headersData?.lapse_runs?.length === 0 ? { justifyContent: "center" } : {}}>
                                            {"Run Results"}
                                            {headersData?.ui_runs?.length > 1 && <ArrowDropDown/>}
                                        </RunResultsButton>
                                        {
                                            headersData?.ui_runs?.length > 1 &&
                                        <Select className={classes.runsSelect} open={uiRunsSelectOpen} value={""} onChange={(e) => handleDataModelingClick(e.target.value)}>
                                                {
                                                    [...headersData.ui_runs]
                                                        ?.sort((a,b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf())
                                                        ?.map((item) => <MenuItem className={classes.runsSelectItem} value={item}>{item?.project?.project_name} {item?.model_type}</MenuItem>) 
                                                }
                                        </Select> 
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.tableContainer}>
                            <TableContainer style={!expandNewBusinessTable ? { overflow: "hidden", minHeight: 120, maxHeight: 287 } : { minHeight: 287}}>
                                <Table>
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                        <TableCell component="th" scope="row" className={classes.tableHeaderCellId}>Policyholder Stories</TableCell>
                                            <TableCell component="th" scope="row">{isAnnuties ? "Total Prospects" : "Number of Prospects"}</TableCell>
                                            <TableCell component="th" scope="row">{isAnnuties ? "AUM" : "Premium"}</TableCell>
                                            <TableCell component="th" scope="row">{isAnnuties ? "Assignable Prospects" : "New Policies In Pipeline"}</TableCell>
                                            <TableCell component="th" scope="row">{isAnnuties ? "AUM of Assignable Prospects" : "New Premium In Pipelin"}</TableCell>
                                            <TableCell component="th" scope="row" className={classes.tableCellAlignStart}>Recommended action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        <TableRow style={{ background: "#F3F9FD", height: 8}}/>
                                        {
                                            tableData?.opportunitiesUI?.map(
                                                (data) => (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={classes.tableCellId}>
                                                            {!data["opportunity"] ? "N/A" : data["opportunity"]?.length < 33 ? data["opportunity"] : <InfoTooltip title={data["opportunity"]}><Typography>{data["opportunity"].slice(0, 32)}...</Typography></InfoTooltip>}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {<NumberFormat value={data["prospects"] ? data["prospects"] : 0} displayType={'text'} thousandSeparator={true}/>}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {nFormatter(data["potential_premium"], 2, currency)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {<NumberFormat value={data["assignable_prospects"] ? data["assignable_prospects"] : 0} displayType={'text'} thousandSeparator={true}/>}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {nFormatter(data["assignable_prospects_potential_premium"], 2, currency)}
                                                        </TableCell>      
                                                        <TableCell component="th" scope="row" className={clsx(classes.tableCellAlignStart, classes.tableCellUI)}>
                                                            {<CampaignTypeSelect defaultValue={data["campaign"]} story={data["opportunity"]} model="ui" onChange={handleChangeStory}/>}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container justifyContent="center" className={classes.expandContainer}>
                                <ExpandButton disabled={!tableData?.opportunitiesUI?.length} onClick={() => setExpandNewBusinessTable(!expandNewBusinessTable)}>
                                    <IconArrowUp style={expandNewBusinessTable ? {} : { rotate: "180deg" }}/>
                                    {expandNewBusinessTable ? "Show less" : "Show all"}
                                </ExpandButton>
                            </Grid>                               
                        </Grid>
                    </Grid>   
                </Grid>
                
                <Grid container justifyContent="center" style={{ position: "fixed", bottom: 0, zIndex: 900 }}>

                    <Grid container alignItems="center" className={classes.tableFooterContainer}>
                        <div className={classes.footerGrid}>
                            <div className={classes.footerGridItem}>Total for all</div>
                            <div className={classes.footerGridItem} style={tableData.total_policies === tableData.total_assignable_prospects ? { visibility: "hidden"} : {}}><NumberFormat value={tableData.total_policies} displayType={'text'} thousandSeparator={true}/></div>
                            <div className={classes.footerGridItem} style={tableData.total_premium === tableData.total_assignable_prospects_potential_premium ? { visibility: "hidden"} : {}}>{nFormatter(tableData.total_premium, 2, currency)}</div>
                            <div className={classes.footerGridItem}><NumberFormat value={tableData.total_assignable_prospects} displayType={'text'} thousandSeparator={true}/></div>
                            <div className={classes.footerGridItem}>{nFormatter(tableData.total_assignable_prospects_potential_premium, 2, currency)}</div>
                            <div className={classes.footerGridItem}>
                                {
                                    !isBroker &&
                                    <RunCampaignsButton disableRipple onClick={handleCampaignsPopupOpen}>
                                        Run All Campaigns
                                    </RunCampaignsButton>                                       
                                }
                            </div>
                        </div> 

                    </Grid>
                </Grid>
                </>}
                
            </Grid>

            <CreateCampaignsDialog
            open={openCampaignsDialog}
            onClose={handleCloseCampaignsDialog}
            onConfirm={handleRunAllCampaigns}
            provider={provider}
            setProvider={setProvider}
            demo={userType === "platform-anonym"}
            />
            <UnregisteredPopup open={openRegisterDialog} onClose={() => setOpenRegisterDialog(false)}/>
        </Grid>
    )
}

export default Opportunities